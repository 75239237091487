<template>
  <div class="app">
    <div style="border-top: solid white 1px;" class="bottom-navigation">
      <a class="nav-item" @click="showMenu">
        <i class="bi bi-list"></i>
        <span class="item-name">Menu</span>
      </a>
      <div class="major-counter">
        <router-link to="/">
          <i class="bi bi-house text-dark"></i>
        </router-link>
      </div>

      <router-link v-show="!isLoggedIn" to="/my-account" class="nav-item">
        <i class="bi bi-person-fill"></i>
        <span class="item-name">Login</span>
      </router-link>
    </div>

    <!-- share bet modal -->
    <span id="open-sharebet" class="hidden"></span>

    <span id="betslip-init" class="hidden"></span>
  </div>

</template>

<script>

// import Pick from "./Pick";

export default {
  name: "bottom-navigation",
  props: {
    page: {
      required: false
    },
  },
  data: function () {
    return {
      //
    }
  },
  methods: {
    //
  },
  computed: {
    iconSize: function () {

      return 28;
    },
    profile: function () {

      return this.myProfile;

    },

  },
  filters: {
    //
  },
  watch: {
    //
  },
  components: {
    //
  },
  mounted: function () {

    //

  }
}
</script>


<style scoped>
.app {
  display: flex;
  flex-direction: column;
  height: auto;
  overflow: hidden; /* Avoid scroll conflicts */
}

.odds-payout {
  position: fixed;
  bottom: 50px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: var(--secondary);
  border-bottom: 1px solid #ccc;
  height: 1em;
  max-width: 768px;
  margin: 0 auto;
}

.total-odds,
.possible-payout {
  font-size: 14px;
  color: #000000;
}

.bottom-navigation {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--red);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  height: 50px;
  transition: transform 0.3s ease-in-out;
  max-width: 768px;
  margin: 0 auto;
  z-index: 500; /* Ensure it's below other content */
}

.bottom-navigation.sticky {
  transform: translateY(0);
}

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #fff;
  line-height: 1;
}

.item-name {
  margin-top: 5px;
}

.nav-item>i {
  font-size: 20px;
  margin-right: 10px;
}

.nav-item>span {
  font-size: 14px;
}

.counter {
  font-size: 10px;
  position: relative;
  top: 5px !important;
  margin-left: 17px;
  color: var(--secondary);
}

.major-counter {
  width: 1.6em;
  height: 1.6em;
  font-size: 24px;
  font-weight: bold;
  color: #000;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  margin: 0 15px;
  border-radius: 50%;
  text-align: center;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>